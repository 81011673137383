export default [
  {
    path: "/marketing/daily-rebate",
    name: "marketing-daily-rebate",
    component: () => import("@/views/marketing/DailyRebate.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Daily rebate",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/marketing/daily-vip",
    name: "marketing-daily-vip",
    component: () => import("@/views/marketing/DailyVip.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Daily vip",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/marketing/daily-reward",
    name: "marketing-daily-reward",
    component: () => import("@/views/marketing/DailyReward.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Daily reward",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/marketing/wager-summary",
    name: "marketing-wager-summary",
    component: () => import("@/views/marketing/WagerSummary.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Wager summary",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/marketing/promotion",
    name: "marketing-promotion",
    component: () => import("@/views/marketing/Promotion.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Promotion",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/marketing/rebate-ratio",
    name: "marketing-rebate-ratio",
    component: () => import("@/views/marketing/AgencyRadio.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Rebate Ratio",
          active: true,
        },
      ],
      action: "read",
      resource: "rebateratio",
    },
  },
  {
    path: "/marketing/upgrade-level",
    name: "marketing-upgrade-level",
    component: () => import("@/views/marketing/UpgradeLevel.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "User upgrade level",
          active: true,
        },
      ],
      action: "read",
      resource: "levelrankmanagement",
    },
  },
];
